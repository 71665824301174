import React from "react"
import { Link } from "gatsby"
import FormattedDate from "../Post/FormattedDate"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const FeaturedImage = ({ post }) => {
  const featuredImage = getFeaturedImage(post, post.mdxAST);
  if (featuredImage !== undefined && featuredImage !== null) {
    return (<img src={featuredImage} />)
  }

  return (<DefaultFeaturedImage />);
}

// Returns a featured image using the top image from the post if it exists.
const getFeaturedImage = (post, mdxAST) => {
  if (post.frontmatter.featured_image) {
    if (post.frontmatter.featured_image === 'fallback') {
      return null;
    }
    
    return post.frontmatter.featured_image;
  }
  const firstNode = mdxAST.children[0];
  if (firstNode.type === "paragraph") {
    const firstInnerNode = firstNode.children[0];
    if (firstInnerNode.type === "image") {
      return firstInnerNode.url;
    }
  }
  if (firstNode.type === "image") {
    return firstNode.url;
  }

  return null;
}

const DefaultFeaturedImage = () => {
  const Wrapper = styled.div`
  width: 100%;
  position: relative;
  `
  const LogoImageWrapper = styled.div`
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .gatsby-image-wrapper {
      position: unset;
    }
  `

  return (
    <Wrapper>
      <LogoImageWrapper>
        <StaticImage alt="Default elopement photographer blog image watermark" src="../../images/logos/Alt-Logo-6-blue-500px.png" height={511} objectFit="contain" />
      </LogoImageWrapper>
      <StaticImage alt="Hurricane-Ridge-Adventure-Wedding-Olympic-National-Park-Elopement-Photographer" src="../../images/theme/mountains/Hurricane-Ridge-Adventure-Wedding-Olympic-National-Park-Elopement-Photographer-5.jpg" width={768} />
    </Wrapper>
  )
}

const BlogIndexEntry = (post) => {
  return (
    <Link to={`/${post.slug}`}>
      {PostImage(post)}

      <h2>{post.frontmatter.title}</h2>
      <PostDate>{FormattedDate(post.frontmatter.date)}</PostDate>
    </Link>
  )
}

const paginatedUrl = (baseUrl, pageNumber) => {
  if (pageNumber === 1) {
    return baseUrl;
  }

  return `${baseUrl}/page/${pageNumber}`;
}

const getPreviousPage = (baseUrl, props) => {
  if (props.pageContext.currentPage === 1) {
    return getBlankPaginatedLink();
  }

  const previousPage = paginatedUrl(baseUrl, props.pageContext.currentPage - 1)
  return getPaginatedLink(previousPage, "Previous Page");
}

const getNextPage = (baseUrl, props) => {
  if (props.pageContext.currentPage === props.pageContext.numPages) {
    return getBlankPaginatedLink();
  }

  // const nextPage = paths.blog_page(props.pageContext.currentPage+1)
  const nextPage = paginatedUrl(baseUrl, props.pageContext.currentPage + 1)
  return getPaginatedLink(nextPage, "Next Page");
}

const getBlankPaginatedLink = () => {
  return (<div className="blank pure-u pure-u-1-2" />)
}

const getPaginatedLink = (url, text) => {
  return (<div className="pure-u pure-u-1-2"><Link className="pure-button full-touch-button" to={url}>{text}</Link></div>)
}

export default FeaturedImage
