import React from "react"
import { graphql } from "gatsby"
import paths from "../../constants/paths"
import BlogIndexCommon from "./BlogIndexCommon"
import Seo from "../SEO"

const BlogIndex = (props) => {
  return (
    <>
      <Seo title={paths.blog.title} location={props.location} />
      <BlogIndexCommon title="Blog Posts" baseUrl={paths.blog.url} props={props} />
    </>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          mdxAST
          frontmatter {
            title
            date
            seo_title
            seo_description
            featured_image
          }
          slug
        }
      }
    }
  }
`
export default BlogIndex
