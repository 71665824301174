import React from "react"
import { Link } from "gatsby"
import FormattedDate from "../Post/FormattedDate"
import styled from "styled-components"
import loadable from '@loadable/component'
import FeaturedImage from './FeaturedImage'
const Pagination = loadable(() => import("./Pagination"))

const ContainerWrapper = styled.div`
  background-color: var(--brand-color-off-white);
  overflow: auto;
`

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 2rem;
`

const PostDate = styled.div`
  @media (min-width: 768px) {
    font-size: 1rem;
  } 
  
  @media (min-width: 1440px) {
    font-size: 1.5rem;
  }
`

const BlogPostList = styled.ul`
  @media (min-width: 768px) {
    padding: 1rem;
  }
`

const BlogPostListItem = styled.li`
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 0;
    margin-top: 0;
  }

  a {
    text-decoration: none;
    color: var(--brand-font-body);
  }

  @media (min-width: 768px) {
    padding: 0.25rem;

    h2 {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 1440px) {
    h2 {
      font-size: 2rem;
    }

    padding: 0.5rem;
  }
`

const BlogIndexCommon = ({ title, baseUrl, props }) => {
  const { edges: posts } = props.data.allMdx

  return (
    <ContainerWrapper>
      <Container>
        <h1>{title}</h1>
        <BlogPostList className="pure-g">
          {posts.map(({ node: post }) => (
            <BlogPostListItem className="pure-u-1-3 pure-u" key={post.id}>
              {BlogIndexEntry(post)}
            </BlogPostListItem>
          ))}
        </BlogPostList>
        <Pagination baseUrl={baseUrl} props={props}/>
      </Container>
    </ContainerWrapper>
  )
}

const BlogIndexEntry = (post) => {
  return (
    <Link to={`/${post.slug}`}>
      <FeaturedImage post={post} />

      <h2>{post.frontmatter.title}</h2>
      <PostDate>{FormattedDate(post.frontmatter.date)}</PostDate>
    </Link>
  )
}

export default BlogIndexCommon
